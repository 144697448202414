import { BC_TOKEN_KEY } from '@/lib/constants'

class AuthService {
	setToken(newToken: string) {
		localStorage.setItem(BC_TOKEN_KEY, newToken)
	}

	getToken() {
		return localStorage.getItem(BC_TOKEN_KEY)
	}
}

export default new AuthService()
