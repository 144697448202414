
import { computed, defineComponent, watch } from 'vue'
import { useStore } from 'vuex'
import queryString from 'query-string'

import AuthService from '@/services/auth'
import { StoreState } from '@/store'
import { useRouter } from 'vue-router'
import { RouteNames } from '@/types/routes'

export default defineComponent({
	name: 'SandboxPage',
	components: {},
	setup() {
		const store = useStore<StoreState>()
		const router = useRouter()
		// gets storeHash from a link.
		const { storeId } = queryString.parse(location.search)

		// fetch sandbox token.
		store.dispatch('user/sandbox/getSandboxToken', storeId)

		// get token state
		const token = computed(() => store.state.user.sandbox.data)

		// watchs token changes.
		watch(token, (newToken) => {
			if (newToken) {
				AuthService.setToken(newToken)

				router.push({ name: RouteNames.ROOT })
			}
		})

		return {
			token,
		}
	},
})
